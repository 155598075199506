import React from 'react';
import classnames from 'classnames';

import { Button, Icon } from 'components/ui';
import { KEY_CODES } from 'constants/keycodes';

import type { ButtonProps } from 'components/ui';

import type { Size } from 'types';
import { Sizes } from 'types';

import styles from './ProductAddToCart.module.scss';

// Constants

const ICON_SIZES = {
  [Sizes.SMALL]: 16,
  [Sizes.MEDIUM]: 20,
  [Sizes.LARGE]: 24,
};

//

interface IIcon {
  name: string,
  strokeWidth?: number,
  fromTop?: boolean,
  isVisible: boolean
}

interface Props {
  className?: string,
  //
  title?: string,
  //
  size: Size,
  variant?: ButtonProps['variant'],
  icons: IIcon[],
  //
  isFixed?: boolean,
  isEditMode?: boolean,
  //
  disabled: boolean,
  //
  onClick: () => void,
  onEsc: (e: React.KeyboardEvent<HTMLButtonElement>) => void,
  onBlur: (e: React.FocusEvent<HTMLButtonElement>) => void
}

const ProductQuantityButton = (props: Props) => {
  const {
    className,
    //
    title,
    //
    size,
    variant = 'subtle',
    icons = [],
    //
    isFixed,
    isEditMode,
    //
    disabled,
    //
    onClick,
    onEsc,
    onBlur
  } = props;

  // Props

  // Render

  return (
    <Button
      className={classnames(
        styles.btn,
        styles.quantityBtn,
        { [styles.fixed]: isFixed },
        { [styles[size]]: size },
        className
      )}
      contentClassName={styles.btnContent}
      //
      title={title}
      //
      size={size}
      variant={variant}
      //
      disabled={disabled}
      //
      onClick={onClick}
      onBlur={onBlur}
      onKeyDown={(e) => {
        if (e.keyCode === KEY_CODES.ESC) {
          onEsc(e);
        }
      }}
      //
      data-prevent-manual-edit-close={isEditMode}
      //
      aria-label="Edit quantity"
    >

      {/* Icons */}
      {
        !!icons?.length && icons.map((icon, index) => {
          const {
            name, strokeWidth, isVisible, fromTop
          } = icon;
          return (
            <div
              key={`${index}-${name}`}
              className={classnames(
                styles.icon,
                { [styles.visible]: isVisible },
                { [styles.top]: fromTop },
              )}
            >
              <Icon
                name={name}
                strokeWidth={strokeWidth}
                size={ICON_SIZES[size]}
              />
            </div>
          );
        })
      }

    </Button>
  );
};

export default ProductQuantityButton;
