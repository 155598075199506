import React from 'react';

import { cx } from 'helpers/StylingHelpers';

import Icon from '../Icon/Icon';

import type { ValueOf } from 'types';

import styles from './Alert.module.scss';

export const VARIANTS = {
  DEFAULT: 'default', // yellow
  SUCCESS: 'success', // green
  PRIMARY: 'primary', // green, including text
  ERROR: 'error', // red
  INFO: 'info', // blue
  WARNING: 'warning', // yellow
  WARNING_V2: 'warningV2', // yellow, including text
  PROMOTION: 'promotion', // orange
  GENIUS: 'genius', // purple
  CUSTOM: 'custom', // requires custom colors
} as const;

export const SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
} as const;

const ICON_SIZES = {
  [SIZES.SMALL]: 18,
  [SIZES.MEDIUM]: 24,
  [SIZES.LARGE]: 24,
};

const CLOSE_ICON_SIZES = {
  [SIZES.SMALL]: 14,
  [SIZES.MEDIUM]: 16,
  [SIZES.LARGE]: 16,
};

const CHEVRON_ICON_SIZES = {
  [SIZES.SMALL]: 18,
  [SIZES.MEDIUM]: 22,
  [SIZES.LARGE]: 22,
};

interface Props {
  classNames?: {
    root?: string,
    content?: string,
    children?: string,
    inner?: string,
    icon?: string,
    title?: string,
    description?: string
  },
  className?: string,
  contentClassName?: string,
  childrenClassName?: string,
  innerClassName?: string,
  iconClassName?: string,
  titleClassName?: string,
  descriptionClassName?: string,

  backgroundColor?: string,
  iconColor?: string,
  titleColor?: string,
  textColor?: string,

  size?: ValueOf<typeof SIZES>,
  variant?: ValueOf<typeof VARIANTS>,

  icon?: React.ReactNode,
  title?: string,
  description?: string | ((props: Props) => JSX.Element),

  withCenteredIcon?: boolean,
  withCircledIcon?: boolean,

  onClose?: () => void,
  onClick?: () => void,

  children?: React.ReactNode
}

type Ref = (
  | HTMLButtonElement
  | HTMLDivElement
);

const Alert = React.forwardRef<Ref, Props>((props: Props, ref: React.Ref<Ref>) => {
  const {
    classNames = {},
    className, contentClassName, childrenClassName, innerClassName,
    iconClassName, titleClassName, descriptionClassName,
    backgroundColor,
    iconColor,
    titleColor,
    textColor,
    size = SIZES.MEDIUM,
    variant = VARIANTS.WARNING,
    icon,
    title,
    description,
    withCenteredIcon,
    withCircledIcon,
    onClick,
    onClose,
    children
  } = props;

  const classes = cx(
    styles.root,
    { [styles[variant]]: variant },
    { [styles[size]]: size },
    { [styles.withIcon]: !!icon },
    { [styles.clickable]: onClick },
    { [styles.centered]: withCenteredIcon },
    { [styles.highlightIcon]: withCircledIcon },
    className,
    classNames.root
  );

  const handleClose = (e: React.MouseEvent) => {
    if (onClose) {
      e.stopPropagation();
      onClose();
    }
  };

  const Tag = onClick ? 'button' : 'div';

  return (
    <Tag
      className={classes}
      onClick={onClick}
      ref={ref as any} // TODO fix type
      style={{
        '---background-color': backgroundColor,
        '---icon-color': iconColor,
        '---title-color': titleColor,
        '---text-color': textColor,
      } as React.CSSProperties}
    >

      {/* Inner */}
      <div className={cx(styles.inner, innerClassName, classNames.inner)}>

        {/* Icon */}
        {
          icon && (
            <span className={cx(styles.icon, { [styles.custom]: typeof icon !== 'string' }, iconClassName, classNames.icon)}>
              {
                typeof icon === 'string'
                  ? <Icon name={icon} size={ICON_SIZES[size]} />
                  : icon
              }
            </span>
          )
        }

        {/* Content */}
        <div className={cx(styles.content, contentClassName, classNames.content)}>

          {/* Title */}
          {
            title && (
              <div
                className={cx(styles.title, titleClassName, classNames.title)}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )
          }

          {/* Description */}
          {
            description && (
              <>
                {
                  typeof description === 'function'
                    ? description(props) : (
                      <div
                        className={cx(styles.description, descriptionClassName, classNames.description)}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: description }}
                      />
                    )
                }
              </>
            )
          }
        </div>

        {/* Close / Click icon */}
        {
          (onClick || onClose) && (
            <div className={cx(styles.secondary, { [styles.close]: onClose })}>
              {
                onClose
                  ? (
                    <div className={styles.secondaryIcon} aria-label="Close" onClick={handleClose}>
                      <Icon name="x" size={CLOSE_ICON_SIZES[size]} strokeWidth={2} />
                    </div>
                  ) : (
                    <div className={styles.secondaryIcon}>
                      <Icon name="chevron-right" size={CHEVRON_ICON_SIZES[size]} strokeWidth={2} />
                    </div>
                  )
              }
            </div>
          )
        }
      </div>

      {/* Children */}
      {
        children && (
          <div className={cx(styles.children, childrenClassName, classNames.children)}>
            {children}
          </div>
        )
      }
    </Tag>
  );
});

export default React.memo(Alert);
