import React from 'react';
import { useRouter } from 'next/router';
import classnames from 'classnames';

import { Menu, MenuItemLink } from 'components/navigation';
import { APP_ROUTES } from 'constants/routes';
import { useAccountMenu } from 'hooks/useAccountMenu';

import styles from './AccountMenu.module.scss';

interface Props {
  //
  className?: string,
  spotlightClassName?: string,
  spotlightItemProps?: Partial<React.ComponentProps<typeof MenuItemLink>>,
  menuProps?: Partial<React.ComponentProps<typeof Menu>> & {
    logoutClassName?: string
  },
  //
  withSpotlight?: boolean,
  withMenu?: boolean,
  //
  isDesktop?: boolean
  //
}

const AccountMenu = (props: Props) => {

  const {
    className,
    spotlightClassName,
    spotlightItemProps,
    menuProps,
    //
    withSpotlight = true,
    withMenu = true,
    //
    isDesktop,
  } = props;

  // Props

  const {
    className: menuClassName,
    itemClassName,
    linkClassName,
    linkActiveClassName,
    linkIconClassName,
    separatorClassName,
    logoutClassName,
    ...restMenuProps
  } = menuProps || {};

  // Hooks

  const router = useRouter();

  const {
    items,
    spotlightedItems
  } = useAccountMenu({
    isDesktop,
    logoutClassName,
  });

  // Render

  return (
    <div className={classnames(styles.root, className)}>

      {/* Spotlight */}
      {
        withSpotlight && (
          <div className={classnames(styles.spotlight, spotlightClassName)}>
            {
              spotlightedItems.map((item, index) => {
                const { href } = item || {};
                const isActive = (
                  href === APP_ROUTES.ACCOUNT
                    ? router.asPath === href
                    : router.asPath.startsWith(href)
                );
                return (
                  <MenuItemLink
                    key={`spotlighted-item-${index}`}
                    className={styles.item}
                    activeClassName={styles.active}
                    labelClassName={styles.label}
                    item={{
                      ...item,
                      badge: null
                    }}
                    column
                    active={isActive}
                    {...spotlightItemProps}
                  />
                );
              })
            }
          </div>
        )
      }

      {/* Menu */}
      {
        withMenu && (
          <Menu
            className={classnames(
              styles.menu,
              menuClassName
            )}
            itemClassName={classnames(
              styles.item,
              itemClassName,
            )}
            linkClassName={classnames(
              styles.link,
              linkClassName,
            )}
            linkActiveClassName={classnames(
              styles.active,
              linkActiveClassName,
            )}
            linkIconClassName={classnames(
              styles.icon,
              linkIconClassName,
            )}
            separatorClassName={classnames(
              styles.separator,
              separatorClassName
            )}
            items={items}
            dataTestId="account-popover"
            {...restMenuProps}
          />
        )
      }

    </div>
  );
};

export default AccountMenu;
