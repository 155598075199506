import React from 'react';
import classnames from 'classnames';

import { isArrayEmpty } from 'helpers/ArrayHelpers';
import { createObjectMap } from 'helpers/ObjectHelpers';

import List from './List';

import type { IListItem } from './List';

import type { IGroup, IGroupHeaderIcons, IGroups } from 'types';

import styles from './GroupedList.module.scss';

export interface IGroupedListSlot {
  position: number
}

interface Props<I extends IListItem<I>, S extends IGroupedListSlot> {
  //
  className?: string,
  groupClassName?: string,
  groupHeaderClassName?: string,
  groupTitleClassName?: string,
  groupIconClassName?: string,
  groupListClassName?: string,
  groupItemClassName?: string,
  slotClassName?: string,
  //
  withBorders?: boolean,
  //
  items: I[],
  slots?: S[],
  icons?: IGroupHeaderIcons,
  //
  groupItems: (items: I[]) => IGroups<I>,
  //
  getItemKey: (item: I) => string | number,
  renderItem: (item: I) => React.ReactNode,
  renderSlot?: (slot: S) => React.ReactNode
}

function GroupedList<I extends IListItem<I>, S extends IGroupedListSlot>(props: Props<I, S>) {
  const {
    //
    className,
    groupClassName,
    groupHeaderClassName,
    groupTitleClassName,
    groupIconClassName,
    groupListClassName,
    groupItemClassName,
    slotClassName,
    //
    withBorders,
    //
    items,
    slots = [],
    icons,
    //
    groupItems,
    //
    getItemKey,
    renderSlot,
    renderItem,
  } = props;

  // Props

  // --- Groups

  const groups: IGroups<I> = React.useMemo(() => {
    return groupItems(items);
  }, [items]);

  // --- Slot Map

  const slotMap: { [position: string]: S[] } = React.useMemo(() => {
    return createObjectMap(slots, 'position', true);
  }, [slots]);

  // --- Rows

  const rows = React.useMemo(() => {
    return createRowArray<I, S>(groups, slotMap);
  }, [groups, slotMap]);

  // --- Appended Slots

  // Helpers

  const getGroupIcon = React.useCallback((name: string) => {
    return icons[name] || null;
  }, [icons]);

  // Empty

  if (items.length === 0 || groups.length === 0) return null;

  // Render

  return (
    <div className={classnames(styles.root, className)}>

      {/* Groups */}
      {
        rows.map(({ position, group, slots: rowSlots }) => {

          // Props

          const {
            groupClassName: currentGroupClassName,
            name: title,
            icon,
          } = group || {};

          // Image

          const image = getGroupIcon(title);

          // Group Render

          return (
            <React.Fragment key={`row-${position}`}>

              {/* Slot */}
              {
                rowSlots && rowSlots.map((slot: S, slotIndex: number) => {
                  return (
                    <div
                      key={`slot-${position}-${slotIndex}`}
                      className={classnames(styles.slot, slotClassName)}
                    >
                      {renderSlot(slot)}
                    </div>
                  );
                })
              }

              {/* Group */}
              {
                !isArrayEmpty(group?.items) && (
                  <div
                    className={classnames(
                      styles.group,
                      { [styles.bordered]: withBorders },
                      groupClassName,
                      currentGroupClassName
                    )}
                  >

                    {/* List */}
                    <List<I>
                      className={groupListClassName}
                      headerClassName={groupHeaderClassName}
                      headerIconClassName={groupIconClassName}
                      headerTitleClassName={groupTitleClassName}
                      itemClassName={groupItemClassName}
                      withBorders={withBorders}
                      items={group?.items}
                      header={{
                        title,
                        icon,
                        image,
                      }}
                      getItemKey={getItemKey}
                      renderItem={renderItem}
                    />
                  </div>
                )
              }

            </React.Fragment>
          );

        })
      }

    </div>
  );
}

//

function createRowArray<I, S>(
  groups: IGroups<I>,
  slotMap: { [position: string]: S[] }
): Array<{
    position: number,
    group?: IGroup<I>,
    slots?: S[]
  }> {
  // Combine all positions into a Set to remove duplicates
  const allPositions = new Set<number>([
    ...groups.map((_, index) => index),
    ...Object.keys(slotMap).map((key) => parseInt(key, 10)),
  ]);

  // Create a sorted array of positions
  const sortedPositions = Array.from(allPositions).sort((a, b) => a - b);

  // Build the resulting array
  return sortedPositions.map((position) => ({
    position,
    group: groups[position], // Group will be undefined if no group exists at this position
    slots: slotMap[position], // Slots will be undefined if no slots exist at this position
  }));
}

// Export

export default GroupedList;
