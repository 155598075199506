import React from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { AccountMenu } from 'components/account';
import { Accordion, Avatar } from 'components/ui';
import { APP_ROUTES } from 'constants/routes';
import { useUser } from 'hooks/data/useUser';

import { useAccountMenu } from 'hooks/useAccountMenu';

import LoginPanel from '../LoginPanel';

import type { IMenuItemLink, IUser } from 'types';

import styles from './AccountMenuMobile.module.scss';

const AccountMenuMobile = () => {

  const { data: user } = useUser({ isOptional: true });

  // Not authenticated
  if (!user) {
    return (
      <LoginPanel />
    );
  }

  // Authenticated
  return (
    <MenuPanel
      user={user}
    />
  );

};

// Menu Panel

const MenuPanel = (props: {user: IUser}) => {

  const { user } = props;

  // Hooks

  const { t } = useTranslation();
  const router = useRouter();

  const { items } = useAccountMenu({ isDesktop: false });

  // Props

  const currentPath = router.asPath;
  const accountMenuPaths = items.filter((item) => item !== 'separator').map((item) => (item as IMenuItemLink)?.href);
  const isAccordionExpanded = accountMenuPaths.includes(currentPath);

  // Render

  return (
    <div className={styles.root} key={router.asPath}>
      <Accordion
        className={styles.accordion}
        headerClassName={styles.accordionHeader}
        iconStrokeWidth={2}
        initiallyExpanded={isAccordionExpanded}
        title={(
          <div className={styles.accordionTitle}>
            <Avatar
              user={user}
              className={styles.avatar}
              iconClassName={styles.avatarIcon}
              size={48}
              iconSize={24}
              withGeniusBadge
              geniusBadgeProps={{ withIcon: true }}
            />
            <div className={styles.nameContainer}>
              <div>{t('HEADER.MOBILE.WELCOME_LOGGED_IN')}</div>
              <div className={styles.name}>{user?.fullName}</div>
            </div>
          </div>
        )}
        withAnimation
        extra={(
          <AccountMenu
            className={styles.spotlightMenu}
            withMenu={false}
          />
        )}
      >
        <AccountMenu
          className={styles.menu}
          menuProps={{
            itemClassName: styles.item,
            linkClassName: styles.link,
            linkIconClassName: styles.icon,
            separatorClassName: styles.separator,
            logoutClassName: styles.logout
          }}
          withSpotlight={false}
        />
      </Accordion>
    </div>
  );
};

export default React.memo(AccountMenuMobile);
