import classnames from 'classnames';

import type { DataTestId } from 'types';

import styles from './Label.module.scss';

// Types

interface Props extends DataTestId {
  className?: string,
  label: string,
  required?: boolean,
  hasError?: boolean | string,
  htmlFor?: string
}

const Label = (props: Props) => {
  const {
    className,
    label,
    required,
    hasError,
    dataTestId,
    ...rest
  } = props;

  const classes = classnames(
    styles.label,
    className,
    {
      [styles.hasError]: hasError,
      [styles.required]: required
    }
  );

  return (
    <label
      className={classes}
      data-testid={dataTestId}
      {...rest}
    >
      {label}
      {required && <span className={styles.requiredMarker}>*</span>}
    </label>
  );
};

export default Label;
