import React from 'react';
import { Field } from 'formik';
import TextareaAutosize from 'react-textarea-autosize';
import { debounce, throttle } from 'throttle-debounce';
import classnames from 'classnames';

import { Collapsible } from 'components/animations';
import { KEY_CODES } from 'constants/keycodes';

import Label from '../Label/Label';

import type { FieldProps } from 'formik';
import type { TextareaAutosizeProps } from 'react-textarea-autosize';
import type { DataTestId } from 'types';

import styles from './Textarea.module.scss';

interface Props extends DataTestId, TextareaAutosizeProps {
  className?: string,
  containerClassName?: string,
  labelClassName?: string,
  errorClassName?: string,
  errorVisibleClassName?: string,
  //
  name?: string,
  label?: string,
  labelRequired?: boolean,
  error?: string,
  regex?: string,
  readOnly?: boolean,
  minRows?: number,
  maxRows?: number,
  withoutForm?: boolean,
  withCollapsibleError?: boolean,
  //
  throttled?: boolean,
  delay?: number,
  //
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>,
  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>,
  onEnter?: React.KeyboardEventHandler<HTMLTextAreaElement>,
  onEsc?: React.KeyboardEventHandler<HTMLTextAreaElement>
}

const Textarea = React.forwardRef<HTMLTextAreaElement, Props>((
  props: Props,
  ref: React.Ref<HTMLTextAreaElement>
) => {
  const {
    //
    className,
    containerClassName,
    labelClassName,
    errorClassName,
    errorVisibleClassName,
    //
    name,
    label,
    labelRequired,
    error,
    regex,
    readOnly,
    minRows,
    maxRows,
    withoutForm,
    withCollapsibleError,
    //
    throttled,
    delay = 0,
    //
    onChange,
    onKeyDown,
    onEnter,
    onEsc,
    //
    dataTestId,
    //
    ...rest
  } = props;

  // Hooks

  const [changeEvent, setChangeEvent] = React.useState<React.ChangeEvent<HTMLTextAreaElement> | null>(null);

  // Handlers

  const changeHandler = onChange || (() => {});

  const debouncedOnChange = React.useCallback(
    throttled
      ? throttle(delay, changeHandler)
      : debounce(delay, changeHandler),
    []
  );

  const onChangeHandler = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    onFieldChange: React.ChangeEventHandler<HTMLTextAreaElement>
  ) => {
    if (!onFieldChange && !onChange) return;
    setChangeEvent(e);

    if (onChange) {
      if (regex) {
        const testRegex = (cb: React.ChangeEventHandler<HTMLTextAreaElement>) => {
          const re = new RegExp(regex);
          if (re.test(e.target.value)) {
            cb(e);
          }
        };

        if (delay) {
          testRegex(debouncedOnChange);
        } else {
          testRegex(onChange);
        }
      }
    } else {
      onFieldChange(e);
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (!onChange) return;
    setChangeEvent(e);

    if (regex) {
      const re = new RegExp(regex);
      if (re.test(e.target.value)) {
        if (delay) {
          debouncedOnChange(e);
        } else {
          onChange(e);
        }
      }
    } else if (delay) {
      debouncedOnChange(e);
    } else {
      onChange(e);
    }
  };

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (onKeyDown) {
      onKeyDown(e);
    }
    if (!changeEvent) return;
    if (e.keyCode === KEY_CODES.ENTER && onEnter) {
      if (onChange) {
        onChange(changeEvent);
      }
      onEnter(e);
    } else if (e.keyCode === KEY_CODES.ESC && onEsc) {
      onEsc(e);
    }
  };

  // Without Form

  if (withoutForm) {
    const hasError = !!error;
    return (
      <div
        className={classnames(styles.root, containerClassName)}
        data-testid={dataTestId ? `${dataTestId}.container` : null}
      >
        {label && (
          <Label
            htmlFor={name}
            label={label}
            required={labelRequired}
            hasError={hasError}
            className={classnames(styles.label, labelClassName)}
            dataTestId={dataTestId ? `${dataTestId}.label` : null}
          />
        )}
        <TextareaAutosize
          ref={ref}
          readOnly={readOnly}
          minRows={minRows}
          maxRows={maxRows}
          name={name}
          className={classnames(styles.textarea, className, { [styles.hasError]: hasError })}
          onKeyDown={onKeyDownHandler}
          onChange={handleOnChange}
          data-testid={dataTestId}
          {...rest}
        />
        {
          withCollapsibleError
            ? (
              <Collapsible
                isExpanded={hasError}
              >
                <Error
                  className={errorClassName}
                  isVisible
                  text={error}
                  dataTestId={dataTestId ? `${dataTestId}.error` : null}
                />
              </Collapsible>
            ) : (
              <Error
                className={errorClassName}
                visibleClassName={errorVisibleClassName}
                isVisible={hasError}
                text={error}
                dataTestId={dataTestId ? `${dataTestId}.error` : null}
              />
            )
        }
      </div>
    );
  }

  // With Form

  return (
    <Field name={name}>
      {({ field, meta: { touched, error: formikError } }: FieldProps) => {
        const { onChange: onFieldChange, ...fieldProps } = field;
        const hasError = !!(touched && formikError);

        return (
          <div
            className={classnames(styles.root, containerClassName)}
            data-testid={dataTestId ? `${dataTestId}.container` : null}
          >
            {label && (
              <Label
                className={classnames(styles.label, labelClassName)}
                label={label}
                htmlFor={name}
                required={labelRequired}
                hasError={hasError}
                dataTestId={dataTestId ? `${dataTestId}.label` : null}
              />
            )}
            <TextareaAutosize
              ref={ref}
              readOnly={readOnly}
              minRows={minRows}
              maxRows={maxRows}
              // @ts-ignore
              name={name}
              onChange={(e) => onChangeHandler(e, onFieldChange)}
              className={classnames(styles.textarea, className, { [styles.hasError]: hasError })}
              data-testid={dataTestId}
              {...fieldProps}
              {...rest}
            />
            {
              withCollapsibleError
                ? (
                  <Collapsible isExpanded={hasError}>
                    <Error
                      className={errorClassName}
                      isVisible
                      text={formikError}
                      dataTestId={dataTestId ? `${dataTestId}.error` : null}
                    />
                  </Collapsible>
                ) : (
                  <Error
                    className={errorClassName}
                    visibleClassName={errorVisibleClassName}
                    isVisible={hasError}
                    text={formikError}
                    dataTestId={dataTestId ? `${dataTestId}.error` : null}
                  />
                )
            }
          </div>
        );
      }}
    </Field>
  );
});

// Error

interface ErrorProps extends DataTestId {
  className?: string,
  visibleClassName?: string,
  text?: string,
  isVisible?: boolean
}

const Error = (props: ErrorProps) => {
  const {
    className,
    visibleClassName,
    text,
    isVisible,
    dataTestId
  } = props;

  const classes = classnames(
    styles.error,
    { [styles.visible]: isVisible },
    { [visibleClassName]: isVisible },
    className
  );

  return (
    <div
      className={classes}
      data-testid={dataTestId}
    >
      {text}
    </div>
  );
};

export default Textarea;

// Textarea.propTypes = {
//   className: PropTypes.string,
//   containerClassName: PropTypes.string,
//   labelClassName: PropTypes.string,
//   //
//   name: PropTypes.string,
//   error: PropTypes.string,
//   disabled: PropTypes.bool,
//   hasError: PropTypes.bool,
//   autoFocus: PropTypes.bool,
//   readOnly: PropTypes.bool,
//   minRows: PropTypes.number,
//   maxRows: PropTypes.number,
//   //
//   withoutForm: PropTypes.bool,
//   withCollapsibleError: PropTypes.bool,
//   //
//   onChange: PropTypes.func,
//   onEnter: PropTypes.func,
//   onEsc: PropTypes.func,
//   onKeyDown: PropTypes.func
// };
